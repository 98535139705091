import React from 'react';

const About = (props) => {
   let name;
   let profilepic;
   let bio;
   let street;
   let city;
   let state;
   let zip;
   let phone;
   let email;
   let resumeDownload;
   if (props.data !== undefined) {
      name = props.data.name;
      profilepic = "images/" + props.data.image;
      bio = props.data.bio;
      street = props.data.address.street;
      city = props.data.address.city;
      state = props.data.address.state;
      zip = props.data.address.zip;
      phone = props.data.phone;
      email = props.data.email;
      resumeDownload = props.data.resumedownload;
   }

   return (
      <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic" src={profilepic} alt="Aman Vijay Profile Pic" />
            </div>
            <div className="nine columns main-col">
               <h2>About Me</h2>

               <p>{bio}</p>
               <div className="row">
                  <div className="columns contact-details">
                     <h2>Contact Details</h2>
                     <p className="address">
                        <span>{name}</span><br />
                        <span>{street}<br />
                           {city} {state}, {zip}
                        </span><br />
                        {/* <span>{phone}</span><br /> */}
                        <span>{email}</span>
                     </p>
                  </div>
                  <div className="columns download">
                     <p>
                        <a href={resumeDownload} target="_blank" rel="noreferrer" className="button"><i className="fa fa-download" download></i>Download Resume</a>
                     </p>
                  </div>
               </div>
            </div>
         </div>

      </section>
   );

}
// class About extends Component {
//    render() {

//       if (this.props.data) {
//          var name = this.props.data.name;
//          var profilepic = "images/" + this.props.data.image;
//          var bio = this.props.data.bio;
//          var street = this.props.data.address.street;
//          var city = this.props.data.address.city;
//          var state = this.props.data.address.state;
//          var zip = this.props.data.address.zip;
//          var phone = this.props.data.phone;
//          var email = this.props.data.email;
//          var resumeDownload = this.props.data.resumedownload;
//       }


//    }
// }

export default About;
